
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumbs/breadcrumb";
import Overview from "@/view/pages/resources/documentation/base/pagination/Overview.vue";
import BasicExamples from "@/view/pages/resources/documentation/base/pagination/BasicExamples.vue";

export default defineComponent({
  name: "pagination",
  components: {
    Overview,
    BasicExamples
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Pagination");
    });
  }
});
